/* import __COLOCATED_TEMPLATE__ from './collection-details-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type App from 'embercom/app';

interface Args {
  fromCollectionTree: boolean;
  folder: any;
  app: App;
  onSave: () => void;
  readOnlyIcon: boolean;
}

export default class CollectionDetailsComponent extends Component<Args> {
  @service declare intl: IntlService;

  get titlePlaceholder() {
    let folder = this.args.folder;
    if (folder.hasSelectedLocalizedName && folder.selectedLocalizedContent?.name !== '') {
      return folder.selectedLocalizedContent?.name;
    } else if (
      folder.defaultLocaleId &&
      folder.defaultLocalizedContent &&
      folder.defaultLocalizedContent?.name !== ''
    ) {
      return `(${folder.defaultLocaleId.toUpperCase()}) ${folder.name}`;
    } else {
      return this.intl.t('articles.collections.detail.placeholder.missing_name');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::CollectionDetailsComponent': typeof CollectionDetailsComponent;
    'articles/site/collections/collection-details-component': typeof CollectionDetailsComponent;
  }
}
