/* import __COLOCATED_TEMPLATE__ from './help-center-preview.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type PreviewType } from './react-preview';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    site: HelpCenterSite;
    media: $TSFixMe;
    previewType: PreviewType;
    setPreviewType: (type: PreviewType) => void;
    curvedBorders: boolean;
  };
}

export default class HelpCenterPreview extends Component<Signature> {
  @service declare appService: any;
  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::HelpCenterPreview': typeof HelpCenterPreview;
  }
}
