/* import __COLOCATED_TEMPLATE__ from './phone-pricing-plan-paywall.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

interface Args {
  onClose: () => void;
}

export default class PhonePricingPlanPaywall extends Component<Args> {
  @service declare intl: IntlService;
  @service declare purchaseAnalyticsService: $TSFixMe;

  get benefits() {
    return [
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-1'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-1',
        hasIcIllustrativeIcon: true,
        icon: 'people',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-1-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-1-title',
      },
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-2'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-2',
        hasIcIllustrativeIcon: true,
        icon: 'messenger',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-2-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-2-title',
      },
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-3'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-3',
        hasIcIllustrativeIcon: true,
        icon: 'rule',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-3-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-3-title',
      },
      {
        description: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-4'),
        descriptionTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-4',
        hasIcIllustrativeIcon: true,
        icon: 'goal',
        title: this.intl.t('calling.settings.phone-pricing-plan-paywall.benefit-4-title'),
        titleTranslationKey: 'calling.settings.phone-pricing-plan-paywall.benefit-4-title',
      },
    ];
  }

  get features() {
    return [
      {
        key: 'switch',
      },
      {
        key: 'voicemail',
      },
      {
        key: 'customized_greetings',
      },
      {
        key: 'call_monitoring',
      },
      {
        key: 'callbacks',
      },
      {
        key: 'call_csat',
      },
      {
        key: 'call_overflow',
      },
      {
        key: 'outbound_dialler',
      },
      {
        key: 'call_transfers',
      },
      {
        key: 'call_wrap_up',
      },
    ];
  }

  @action
  postTaskAndTrackAnalyticsEvent() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'chat_to_us',
      context: 'phone_pricing_plan_paywall',
    });
  }

  @action
  openMessenger() {
    this.postTaskAndTrackAnalyticsEvent();

    showNewMessageInIntercomWidget(
      this.intl.t('calling.settings.phone-pricing-plan-paywall.chat-with-us-message'),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhonePricingPlanPaywall': typeof PhonePricingPlanPaywall;
    'calling/settings/phone-pricing-plan-paywall': typeof PhonePricingPlanPaywall;
  }
}
