/* import __COLOCATED_TEMPLATE__ from './article-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TreeItem } from 'embercom/objects/tree-list';
import { type ArticleSummary, type DataObject } from './types';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  item: TreeItem;
}

export default class ArticleItems extends Component<Args> {
  articleDataObject = this.args.item.dataObject as DataObject;
  @tracked loading = false;
  @service declare intl: IntlService;
  @service appService: any;

  get app() {
    return this.appService.app;
  }

  get defaultLocale() {
    return this.articleDataObject.defaultLocale?.();
  }

  get locale() {
    return this.articleDataObject.locale();
  }

  get article() {
    return this.articleDataObject.content as ArticleSummary;
  }

  get selectedLocalizedArticle() {
    return this.article.locales[this.locale];
  }

  get siteProvider() {
    return this.selectedLocalizedArticle?.site_provider;
  }

  get defaultLocalizedArticle() {
    if (!this.defaultLocale) {
      return null;
    }
    return this.article.locales[this.defaultLocale];
  }

  get title() {
    if (this.selectedLocalizedArticle && this.selectedLocalizedArticle.title !== '') {
      return this.selectedLocalizedArticle.title;
    } else if (
      this.defaultLocale &&
      this.defaultLocalizedArticle &&
      this.defaultLocalizedArticle?.title !== ''
    ) {
      return `(${this.defaultLocale.toUpperCase()}) ${this.defaultLocalizedArticle.title}`;
    } else {
      return this.intl.t('articles.editor.untitled-article');
    }
  }

  get status() {
    return this.selectedLocalizedArticle?.state || 'missing';
  }

  get readOnly() {
    return this.article.read_only;
  }

  get contents() {
    return [
      {
        items: [
          {
            text: this.intl.t('articles.collections.actions.delete'),
            icon: 'trash',
            onSelectItem: async () => {
              await this.removeArticle();
            },
            isDestructive: true,
          },
        ],
      },
    ];
  }

  @action
  async removeArticle() {
    if (!this.articleDataObject.removeArticleFromCollection) {
      return;
    }

    try {
      this.loading = true;
      await this.articleDataObject.removeArticleFromCollection();
    } finally {
      this.loading = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::ArticleItem': typeof ArticleItems;
    'articles/site/collections/tree/article-item': typeof ArticleItems;
  }
}
